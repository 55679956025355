<template>
    <v-card class="ma-0 pa-0" flat>
        <v-card-text class="ma-0 pa-0">
            <v-chart class="ma-0 pa-0 chart" :key="updateKey" :option="option" />
        </v-card-text>
    </v-card>
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
]);

export default {
    props: ['data', 
    'dataset2', 
    'dataset2Color', 
    'dataset3', 
    'dataset3Color', 
    'defaultChart',
    'legend', 
    'labels', 
    'title', 
    'textColor', 
    'xAxisLabel', 
    'yAxisLabel', 
    'color', 
    'updateKey'
],
    components: {
        VChart
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    data() {
        return {
            option: {
                title: {
                    text: this.title,
                    show: true,
                    textStyle: {
                        color: this.textColor
                    },
                    bottom: 0
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: this.legend,
                    textStyle: {
                        color: this.textColor
                    },
                },
                toolbox: {
                    show: true,
                    top: 20,
                    feature: {
                        magicType: { type: ['line', 'bar'] },
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.labels,
                    nameLocation: "middle",
                    name: this.xAxisLabel,
                    nameGap: 25
                },
                yAxis: {
                    type: 'value',
                    nameLocation: "middle",
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: this.data,
                        type: this.defaultChart ?? 'line',
                        color: this.color ?? 'grey',
                        smooth: true,
                        symbol: 'circle',
                        showInLegend: true,
                        name:this.legend[0]
                    },
                    {
                        data: this.dataset2,
                        type: 'line',
                        symbol: 'circle',
                        color: this.dataset2Color ?? 'grey',
                        smooth: true,
                        showInLegend: true,
                        name: this.legend[1]
                    },
                    {
                        data: this.dataset3,
                        type: 'line',
                        symbol: 'circle',
                        color: this.dataset3Color ?? 'grey',
                        smooth: true,
                        showInLegend: true,
                        name: this.legend[2]
                    },

                ]
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 36vh
}
</style>